var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{class:_vm.isBordered ? 'border-light-secondary' : ''},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"rounded":"lg","variant":_vm.taskType.variant}},[_c('i',{staticClass:"card-icon",class:_vm.taskType.icon})])],1),_c('div',{staticClass:"d-flex flex-column"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex flex-column "},[_c('span',{staticClass:"text-muted font-weight-bolder "},[_vm._v(_vm._s(_vm.taskType.title))]),_c('h6',{staticClass:"font-weight-bolder small-gap-bottom"},[_vm._v(" "+_vm._s(_vm.task.name)+" ")])])]),_c('b-card-text',[_vm._v(" "+_vm._s(_vm.task.description)+" ")])],1)]),_c('div',{staticClass:"d-flex align-items-center pt-1 mt-1 justify-content-between border-top"},[_c('span',{staticClass:"text-muted"},[_vm._v(" "+_vm._s(_vm.localizeISODate(_vm.task.created_at, 'ID-id'))+" ")]),_c('div',{staticClass:"d-flex justify-content-end"},[(_vm.isContentCreator)?_c('b-button',{staticClass:"d-flex align-items-center small-gap-right",attrs:{"size":"sm","to":{
          name: _vm.checkAuthorizeRole('classroom-detail-exam-edit'),
          params: {
            id: _vm.classroomId,
            contentId: _vm.contentId,
            taskId: _vm.task.id,
          },
        },"variant":"warning"}},[_c('i',{staticClass:"regular-icon align-middle ti ti-pencil "})]):_vm._e(),(_vm.isContentCreator)?_c('b-button',{staticClass:"d-flex align-items-center small-gap",attrs:{"size":"sm","variant":"danger"},on:{"click":function($event){return _vm.deleteTask(_vm.task.id)}}},[_c('i',{staticClass:"regular-icon align-middle ti ti-trash "})]):_vm._e()],1),(!_vm.isContentCreator)?_c('b-button',{staticClass:"d-flex align-items-center",attrs:{"to":{
        name: _vm.checkAuthorizeRole('classroom-detail-exam-submission'),
        params: {
          id: _vm.classroomId,
          contentId: _vm.contentId,
          taskId: _vm.task.id,
        },
      },"variant":"outline-primary"}},[_vm._v(" Kerjakan "),_c('i',{staticClass:"regular-icon align-middle ti ti-chevron-right small-gap-right"})]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }