<template>
  <b-card :class="isBordered ? 'border-light-secondary' : ''">
    <div class="d-flex">
      <div class="mr-1">
        <b-avatar rounded="lg" :variant="taskType.variant">
          <i class="card-icon" :class="taskType.icon" />
        </b-avatar>
      </div>
      <div class="d-flex flex-column">
        <div class="d-flex justify-content-between">
          <div class="d-flex flex-column ">
            <span class="text-muted font-weight-bolder ">{{
              taskType.title
            }}</span>
            <h6 class="font-weight-bolder small-gap-bottom">
              {{ task.name }}
            </h6>
          </div>
        </div>

        <b-card-text>
          {{ task.description }}
        </b-card-text>
      </div>
    </div>
    <div
      class="d-flex align-items-center pt-1 mt-1 justify-content-between border-top"
    >
      <span class="text-muted">
        {{ localizeISODate(task.created_at, 'ID-id') }}
      </span>

      <div class="d-flex justify-content-end">
        <b-button
          v-if="isContentCreator"
          size="sm"
          class="d-flex align-items-center small-gap-right"
          :to="{
            name: checkAuthorizeRole('classroom-detail-exam-edit'),
            params: {
              id: classroomId,
              contentId: contentId,
              taskId: task.id,
            },
          }"
          variant="warning"
        >
          <i class="regular-icon align-middle ti ti-pencil " />
        </b-button>
        <b-button
          v-if="isContentCreator"
          size="sm"
          class="d-flex align-items-center small-gap"
          @click="deleteTask(task.id)"
          variant="danger"
        >
          <i class="regular-icon align-middle ti ti-trash " />
        </b-button>
      </div>
      <b-button
        v-if="!isContentCreator"
        :to="{
          name: checkAuthorizeRole('classroom-detail-exam-submission'),
          params: {
            id: classroomId,
            contentId: contentId,
            taskId: task.id,
          },
        }"
        class="d-flex align-items-center"
        variant="outline-primary"
      >
        Kerjakan
        <i
          class="regular-icon align-middle ti ti-chevron-right small-gap-right"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import client from '@/libs/http/axios-config'
import { localizeISODate } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

import {
  BRow,
  BCol,
  BCard,
  BButton,
  BCardText,
  BAlert,
  BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardText,
    BAlert,
    BAvatar,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
    isBordered: {
      type: Boolean,
    },
    isContentCreator: {
      type: Boolean,
    },
  },
  data() {
    return {
      // default data
      classroomId: this.$router.currentRoute.params.id,
      contentId: this.$router.currentRoute.params.contentId,
    }
  },
  computed: {
    taskType() {
      const taskType = {
        file: {
          title: 'Unggah File',
          icon: 'ti ti-file-upload',
          variant: 'light-warning',
        },
        multiplechoice: {
          title: 'Pilihan Ganda',
          icon: 'ti ti-list-check',
          variant: 'light-success',
        },
        essay: {
          title: 'Essay',
          icon: 'ti ti-notes',
          variant: 'light-info',
        },

        submission: {
          title: 'Unggah Tugas',
          icon: 'ti ti-file-upload',
          variant: 'light-warning',
        },
        exam: {
          title: 'Kuis',
          icon: 'ti ti-list-check',
          variant: 'light-success',
        },
      }

      return taskType[this.task.type]
    },
  },
  methods: {
    localizeISODate,
    checkAuthorizeRole,

    // Delete
    deleteTask(taskId) {
      this.$swal({
        title: 'Anda Yakin?',
        text: 'Data yang dihapus tidak dapat dikembalikan',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya, Hapus!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteTaskRequest(taskId)
        }
      })
    },

    deleteTaskRequest(taskId) {
      client
        .delete(
          `/teachers/classrooms/${this.classroomId}/class-contents/${this.contentId}/tasks/${taskId}`
        )
        .then((response) => {
          this.$swal({
            icon: 'success',
            title: 'Berhasil!',
            text: 'Ujian berhasil dihapus',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(() => {
            this.$router.go()
          })
        })
        .catch((error) => {
          this.$swal({
            icon: 'error',
            title: 'Gagal',
            text: 'Ujian gagal dihapus.',
            customClass: {
              confirmButton: 'btn btn-danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-body {
  min-height: 160px;
}
</style>
